export default defineI18nConfig(() => {
    return {
        legacy: false,
        locales: ['kk', 'en', 'ru'],
        defaultLocale: 'kk',
        messages: {
            kk: {
                welcome: 'Қош келдіңіз',
                titleIndexFaq: 'Сұрақтар және жауаптар',
                group_of_names: 'Атаулар топтамасы',
                documents: {
                    title: 'Құжаттар',
                    contracts: 'Шарттар',
                    legalEntities: 'Жұрилік тұлғалар үшін',
                    individuals: 'Жеке тұлғалар үшін',
                    shippingDocuments: 'Жіберу мен алу үшін құжаттар',
                    tariffs: 'Жүкке алу тарифтері',
                    demo_text: '(демо-мәтін)',
                    description1: 'ПЭК компаниясы арқылы тасымалдау жүзеге асыру мүмкін екі жақтың келісімімен куәландырылмаған. Мұндай жағдайларда біздің веб-сайтымызда еркін қолжетімді түрде орналасқан келісім шарты қолданылады',
                    description2: 'Ресей Федерациясының Азаматтық кодексінің 438-бабының 3-тармағына сәйкес, ұсынысты қабылдау ұсыныстардың мәтінінде көрсетілген шарттарға сәйкес келісімге тең',
                    description3: 'Келісімді қабылдау қызметтерді тапсыру және/немесе жүк әкелу және/немесе қызметтерге төлеу және/немесе жүкті алу арқылы жүзеге асады',
                    description4: 'Клиенттің өтініші бойынша көлік-экспедициялық қызмет көрсету үшін стандартты келісім жасауға болады (демо-мәтінді қараңыз).',
                    variable:'мiндеттi қатты қаптамаға жатады.',
                    attention: 'Оқыңыз.',
                    note: 'Мұндаушының орнында өтіп тасымалдау қызметі туралы стандартты шартты қолдана аласыз (демо мәтін).',
                    legalContract: 'Жұрилік тұлғалар үшін',
                    physicalContract: 'Жеке тұлғалар үшін',
                    accompanyingLetter: 'Кейбір құжаттар',
                    orderAssignment: 'Тапсырыс-міндет',
                    documentsForShipping: 'Жіберу мен алу үшін құжаттар',
                    detailedCMRTemplate: 'CMR толтыру үшін толық үлгі',
                    inventoryAccompanyingLetter: 'Кейбір құжаттар',
                    unifiedProductList: 'Таможендік ыдыстықтар ретінде тиімді және жетілдіруді қамтамасыз ету үшін едін мәтіндікті беру, өндірісті төлеу сараптамасымен (бірлік документтермен бірге)',
                    goodsNotAccepted: 'Жүктер, жүкке алуға қабілетті емес',
                    shippingRates: 'Жүкке алу тарифтері',
                    descriptionTariffs: '«ПЭК» Компаниясы арқылы жүкке алу аймаған, екі тұрақтың қолымен қолданылмаған шартты қалайтындысыз (демо мәтін).',
                    tariffsToKazakhstan: 'Қазақстан бойынша жүкке алу тарифтері',
                    links: {
                        rigidPackaging: 'жебе пакетіндегі жүктердің тізімі',
                        cmrTemplate: 'CMR толтыру үшін толық үлгі'
                    }
                },
                errorModal: {
                    errorTitle: 'Сіздің тапсырысыңыз жіберілмеді!',
                    errorDescription: 'Форманы қайтадан толтырыңыз немесе нөміріне қоңырау шалыңыз -',
                    tryAgain: 'Қайтадан жіберу',
                },
                successModal: {
                    successDescription: 'Біздің менеджер сізге жаңа сұрақтар бойынша өтініштің барлық мәліметтерін бақылау үшін жақын арада қоңырау шалады.',
                },
                expand_the_list: 'Тізімді ашу',
                search_by_group: 'Топ бойынша іздеу',
                select_a_value: 'Мәнді таңдаңыз',
                sm: 'см',
                cargo_search_placeholder: "Жүкті табу үшін кодты енгізіңіз",
                choose_language: "Тілді таңдаңыз"
            },
            en: {
                welcome: 'Welcome',
                titleIndexFaq: 'Q&A',
                group_of_names: 'Group of names',
                documents: {
                    title: 'Documents',
                    contracts: 'Contracts',
                    legalEntities: 'For Legal Entities',
                    individuals: 'For Individuals',
                    shippingDocuments: 'Shipping Documents',
                    tariffs: 'Shipping Tariffs',
                    demo_text: '(demo text)',
                    description1: 'It is possible to carry out transportation through the PEK Company without signing a contract by both parties. In such cases, the contract posted on our website in the public domain applies.',
                    description2: 'In accordance with paragraph 3 of Article 438 of the Civil Code of the Russian Federation, acceptance of an offer is equivalent to concluding a contract on the terms set forth in the offer.',
                    description3: 'Acceptance of the contract is carried out by ordering services and/or delivering cargo and/or paying for services and/or receiving cargo.',
                    description4: `At the client's request, a standard contract for freight forwarding services can be concluded (demo text).`,
                    attention: 'Please read.',
                    variable:'subject to mandatory rigid packaging.',
                    note: 'Upon the client\'s request, a standard transport and forwarding services contract may be concluded (demo text).',
                    legalContract: 'For Legal Entities',
                    physicalContract: 'For Individuals',
                    accompanyingLetter: 'Accompanying Letter',
                    orderAssignment: 'Order Assignment',
                    documentsForShipping: 'Documents for Shipping and Receipt',
                    detailedCMRTemplate: 'Detailed CMR Template',
                    inventoryAccompanyingLetter: 'Inventory Accompanying Letter',
                    unifiedProductList: 'Unified list of products subject to mandatory conformity assessment (confirmation) within the customs union with issuance of unified documents',
                    goodsNotAccepted: 'Goods not accepted for transportation',
                    shippingRates: 'Shipping Rates',
                    descriptionTariffs: 'Shipments can be made through Company "PEC" without a contract signed by both parties (demo text).',
                    tariffsToKazakhstan: 'Shipping tariffs to Kazakhstan',
                    links: {
                        rigidPackaging: 'list of goods',
                        cmrTemplate: 'Detailed CMR Template'
                    }
                },
                errorModal: {
                    errorTitle: 'Your request was not sent!',
                    errorDescription: 'Try filling out the form again or call the number -',
                    tryAgain: 'Try again',
                },
                successModal: {
                    successDescription: 'Our manager will call you shortly to confirm all details regarding your request.',
                },
                expand_the_list: 'Expand the list',
                search_by_group: 'Search by group',
                select_a_value: 'Select a value',
                sm: 'sm',
                cargo_search_placeholder: "Enter the code to find the cargo",
                choose_language: "Choose Language"
            },
            ru: {
                welcome: 'Добро пожаловать',
                titleIndexFaq: 'Вопросы и ответы',
                group_of_names: 'Группа наименований',
                documents: {
                    title: 'Документы',
                    contracts: 'Договоры',
                    legalEntities: 'Для юридических лиц',
                    individuals: 'Для физических лиц',
                    shippingDocuments: 'Документы на получение и отправку',
                    tariffs: 'Тарифы на перевозку',
                    demo_text: ' (демо текст)',
                    description1: 'Осуществлять перевозки через Компанию «ПЭК» можно без оформления договора, подписанного обеими сторонами. В таких случаях действует договор, размещенный на нашем сайте в свободном доступе',
                    description2: 'В соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению договора на условиях, изложенных в оферте.',
                    description3: 'Акцепт договора осуществляется путем заказа услуг и/или сдачи груза и/или оплаты услуг и/или получения груза.',
                    description4: 'По желанию клиента может быть заключён типовой договор на транспортно-экспедиционное обслуживание (демо текст).',
                    note: 'По желанию клиента может быть заключён типовой договор на транспортно-экспедиционное обслуживание (демо текст).',
                    attention: 'Ознакомьтесь',
                    variable: 'подлежащих обязательной жесткой упаковке.',
                    legalContract: 'Для юридических лиц',
                    physicalContract: 'Для физических лиц',
                    accompanyingLetter: 'Сопроводительное письмо',
                    orderAssignment: 'Заказ-поручение',
                    documentsForShipping: 'Документы на отправку и получение грузов',
                    detailedCMRTemplate: 'Подробный шаблон для заполнения CMR',
                    inventoryAccompanyingLetter: 'Бланк сопроводительного письма (описи)',
                    unifiedProductList: 'Единый перечень продукции, подлежащей обязательной оценке (подтверждению) соответствия в рамках таможенного союза с выдачей единых документов',
                    goodsNotAccepted: 'Грузы, не принимаемые к перевозке',
                    shippingRates: 'Тарифы на перевозку',
                    descriptionTariffs: 'Осуществлять перевозки через Компанию «ПЭК» можно без оформления договора, подписанного обеими сторонами (демо текст).',
                    tariffsToKazakhstan: 'Тарифы на перевозки по Казахстану',
                    links: {
                        rigidPackaging: 'с перечнем грузов',
                        cmrTemplate: 'Подробный шаблон для заполнения CMR'
                    }
                },
                errorModal: {
                    errorTitle: 'Ваша заявка не отправлена!',
                    errorDescription: 'Попробуйте заполнить форму заново или позвоните по номеру -',
                    tryAgain: 'Попробовать еще раз',
                },
                successModal: {
                    successDescription: 'Наш менеджер скоро свяжется с вами для уточнения всех деталей по вашему запросу.',
                },
                expand_the_list: 'Раскрыть список',
                search_by_group: 'Поиск по группе',
                select_a_value: 'Выберите значение',
                sm: 'см',
                cargo_search_placeholder: "Введите код чтобы найти груз",
                choose_language: "Выберите язык"
            },
        },
    };
});
